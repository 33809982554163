import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {Icon} from "../../../common/utils/Icon";

function Sidebar({ navigations }) {
  const [sidebar, setSidebar] = useState([]);

  useEffect(() => {
    const nav = navigations.find((nav) => {
      return nav.position === "sidebar";
    });

    if (nav && nav.children) {
      setSidebar(nav.children);
    }
  }, []);

  return (
    <div className="uk-visible@l uk-position-center-right uk-position-z-index uk-position-fixed">
      <ul className="uk-iconnav uk-iconnav-vertical header-icons">
        {sidebar.map((child) => (
          <li key={child._id}>
            <a
              href={(child.page && child.page.url) || child.url}
              target={child.page && child.page.url ? "_self" : "_blank"}
              className="uk-button-primary uk-light uk-text-bolder"
              data-uk-tooltip={`title:${child.tooltip}; pos: left`}
              style={{
                textDecoration: "none",
                writingMode: child.label ? "tb-rl" : "inherit",
                textTransform: "uppercase",
                display: "flex",
                justifyContent: "center",
                minWidth: 50,
                minHeight: 50,
                maxHeight: 220,
                padding: child.label ? "15px 0" : "inherit",
                alignItems: "center",
                transform: child.label ? "rotate(-180deg)" : "none",
              }}
            >
              <Icon icon={child.icon} ratio={1.3} className="light" light/>
              {child.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

function mapStateToProps({ navigations }) {
  return { navigations };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
